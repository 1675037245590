import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '安丰古镇_东台市安丰古镇文化旅游发展有限公司',
    }
  },
  {
    path: '/Content/:id',
    name: 'Content',
    component: () => import('../views/Content.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/Content-EN/:id',
    name: 'Content-EN',
    component: () => import('../views/Content-EN.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/Content-JP/:id',
    name: 'Content-JP',
    component: () => import('../views/Content-JP.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/Content-KO/:id',
    name: 'Content-KO',
    component: () => import('../views/Content-KO.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/Mobile.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/testpubu',
    name: 'testpubu',
    component: () => import('../views/testpubu.vue'),
    meta: {
      title: '安丰古镇移动端初始',
    }
  },
  {
    path: '/mobileIndex',
    name: 'mobileIndex',
    component: () => import('../views/MobileIndex.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/mobileIndexEnglish',
    name: 'mobileIndexEnglish',
    component: () => import('../views/MobileIndexEnglish.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/mobileIndexJapan',
    name: 'mobileIndexJapan',
    component: () => import('../views/MobileIndexJapan.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
  {
    path: '/mobileIndexKorean',
    name: 'mobileIndexKorean',
    component: () => import('../views/MobileIndexKorean.vue'),
    meta: {
      title: '安丰古镇',
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
